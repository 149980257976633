import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { NavBar } from "../NavBar";
import { theme } from "../../theme";
import { HomePage } from "../HomePage";
import { AboutPage } from "../AboutPage";
import { ErrorPage } from "../ErrorPage";

export function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <NavBar />
        <div style={{ paddingTop: '100px' }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}
