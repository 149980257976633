import React from "react";
import { PageContent, PageRoot } from "../common";

export function ErrorPage() {
  return (
    <PageRoot>
      <PageContent>
        <div>This is an error page.</div>
      </PageContent>
    </PageRoot>
  );
}
