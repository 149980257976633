import React from "react";

import styled from "styled-components";

export const PageRoot = styled.div`
  display: flex;
  width: 100vw;
  padding: 16px;
  box-sizing: border-box;
  justify-content: center;
  height: calc(100vh - 100px);
  background-color: ${props => props.theme.colours.secondary};
`;

export const PageContent = styled.div`
  width: 800px;
`;
