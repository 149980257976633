import React from "react";

import styled from "styled-components";

import { PageRoot, PageContent } from "../common";

export function AboutPage() {
  return (
    <PageRoot>
      <PageContent>
        <div>This is an about page.</div>
      </PageContent>
    </PageRoot>
  );
}
