import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as LayersSVG } from "../../svg/layers.svg";

const NavBarContainer = styled.div`
  position: fixed;
  height: 100px;
  width: 100vw;
  padding: 8px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colours.primary};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  align-items: center;
`;

const MainLogo = styled(LayersSVG)`
  height: 50px;
  width: 50px;
  padding: 8px;
`;

const LinkContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const AppLink = styled(Link)`
  width: 120px;
  height: 40px;
  margin: 8px;
  box-sizing: border-box;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
`

export function NavBar() {
  return (
    <NavBarContainer>
      <MainLogo stroke="black" fill="white" />
      <LinkContainer>
        <AppLink to="/">Home</AppLink>
        <AppLink to="/about">About</AppLink>
      </LinkContainer>
    </NavBarContainer>
  );
}
